// src/App.js
import React, { useState } from 'react';
import ChatRoom from './ChatRoom';
import { TextField, Button, Card } from '@material-ui/core';

const ChatComponent = () => {
    
  const [messages, setMessages] = useState([]);
  const [messageInput, setMessageInput] = useState('');

  const handleSendMessage = () => {
    if (messageInput.trim() !== '') {
      setMessages([...messages, messageInput]);
      setMessageInput('');
    }
  };

  return (
    <Card style={{  padding: '10px', minWidth: '100%', margin: '0 auto' }}>
      <ChatRoom messages={messages} />
      <TextField
        label="Add a Note"
        variant="outlined"
        value={messageInput}
        onChange={(e) => setMessageInput(e.target.value)}
        fullWidth
        style={{ marginTop: '120px' }}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleSendMessage}
        style={{ marginTop: '10px' }}
      >
        Add
      </Button>
    </Card>
  );
};
export default ChatComponent;