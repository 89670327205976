import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import HomePageBar from '../components/HomePageBar';
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import AddJobForm from './AddJobForm'; // Import the form component
import LoginBarTop from '../components/LoginBarTop';
import { useLocation, useNavigate } from 'react-router-dom';
import { getOnlineUsers } from '../services/OnlineService';
import UploadImg from '../components/uploadImg';
import Avatar from '@mui/material/Avatar';

const Jobs = () => {
  const location = useLocation();
  const state = location.state || "";
  const realUser = state.realUser || "";
  const nav = useNavigate();
  const [isFormOpen, setIsFormOpen] = useState(false); 
  const [isUpload, setIsUpload] = useState(false);
  const [jobData, setJobData] = useState([]);
  const [buttonImg, setButtonImg] = useState(false);
  const [imgLinkZero, setImgLinkZero] = useState();
  const [imgLinkOne, setImgLinkOne] = useState();
  const handelUrlStringZero = () => {
    const newImg = document.getElementById('mainImg')?.currentSrc
    if (newImg) {
      setImgLinkZero(newImg);
    }
    setButtonImg(true)
  }
  const handelUrlStringOne = () => {
    const newImg = document.getElementById('mainImg')?.currentSrc
    if (newImg) {
      setImgLinkOne(newImg);
    }
    setButtonImg(true)
  }

  let ImgId = document.getElementById("mainImg") || "";

  useEffect(() => {
    getJobs()
  }, [])
  // Function to toggle the form visibility
  const toggleForm = () => {
    if (realUser === "") {
      nav("/Login");
    } else {
      setIsFormOpen(!isFormOpen);
      setIsUpload(!isUpload);
    }
  };
  const getJobs = async () => {
    try {
      const jobDataResponse = await getOnlineUsers();
      const newJobData = jobDataResponse[0].jobs;
      const rows = Object.keys(newJobData).map(key => {
        const { user, jobTitle, location, contact, skills, hours, tags, description, imgLink, imgLink0, imgLink1, link, salary } = newJobData[key];
        return {
          user,
          jobTitle,
          contact,
          description,
          location,
          imgLink,
          imgLink0,
          imgLink1,
          skills,
          hours,
          tags,
          link,
          salary
        };
      });
      // Update the state with the new job data
      setJobData(rows);
    } catch (error) {
      console.error('Error fetching jobs:', error);
      throw new Error('Error fetching jobs');
    }
  };
  return (
    <>
      {realUser !== undefined && realUser !== "" ? (
        <>
          <LoginBarTop realUser={realUser} />
        </>
      ) : <HomePageBar />}
      {/* Button to open the form */}
      {realUser !== undefined && realUser !== "Guest" && <Button style={{ marginLeft: '10px', marginBottom: '10px' }} onClick={toggleForm} variant="outlined" color="primary">
        Add Job
      </Button>}
      {(isFormOpen && realUser !== "") && 
      <AddJobForm onClose={toggleForm} ImgId={ImgId} realUser={realUser} imgLinkZero={imgLinkZero} imgLinkOne={imgLinkOne} />}
      <div style={{ display: 'flex' }}>
        <Typography
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginLeft: '10px',
            marginTop: '20px',
            marginBottom: '20px'
          }}
          gutterBottom
          variant="h4"
          component="h2"
        >
        </Typography>
      </div>

      {(realUser !== "") &&
        <Grid sx={{ marginLeft: "10px" }}  >
         
          {(isUpload && realUser !== "") &&  <>
          <Button variant="outlined" onClick={handelUrlStringZero}>
            Avatar
          </Button>
          <Button sx={{ marginLeft: "10px" }} variant="outlined" onClick={handelUrlStringOne}>
              Top Image
            </Button><UploadImg realUser={realUser} /></>}
        </Grid>}
      {jobData.map((jobInfo, index) => (

        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <CardMedia
              component="img"
              style={{ height: '100px' }}
              image={jobInfo.imgLink1} //  imgLink is the image URL
            />
            <Avatar
              alt="Remy Sharp"
              src={jobInfo.imgLink0}
              sx={{ width: 140, height: 140, top: "-4.5rem", marginLeft: "2rem", border: "0.5rem solid white" }}
            />
            <Typography mt={-9} sx={{ marginLeft: "3.8rem" }} variant="body1" color="textSecondary">
              {jobInfo.user}
            </Typography>
            <CardContent item mt={5} ml={2}>
              <Typography gutterBottom variant="h5" component="div">
                {jobInfo.jobTitle}
              </Typography>
              <Typography mt={1} variant="body2" color="textSecondary">
                {jobInfo.location}
              </Typography>
              <Typography gutterBottom variant="body2" color="textSecondary" component="div">
                {jobInfo.hours}
              </Typography>
              <Typography mt={1} variant="h6" color="textSecondary">
                Information
              </Typography>
              <Typography mt={1} variant="body2" color="textSecondary">
                {jobInfo.description}
              </Typography>
              <Typography mt={1} variant="h6" color="textSecondary">
                Skills
              </Typography>
              <Typography mt={1} variant="body2" color="textSecondary">
                {jobInfo.skills}
              </Typography>
              <Typography mt={1} variant="h6" color="textSecondary">
                Salary
              </Typography>
              <Typography mt={1} variant="body2" color="textSecondary">
                {jobInfo.salary}
              </Typography>
              <Typography mt={1} variant="h6" color="textSecondary">
                Contact
              </Typography>
              <Typography mt={1} variant="body2" color="textSecondary">
                {jobInfo.contact}
              </Typography>
            </CardContent>
            <Button style={{ float: "left", marginTop: "0.5rem", marginRight: "0.5rem" }}
              variant="text"
              size="small"
              color="primary"
              href="https://www.localproject.app/Blogs"
              rel={`noopener noreferrer tag ${jobInfo.tags}`}
            >
              Tags {jobInfo.tags}
            </Button>
            <CardActions style={{ float: "right" }}>
              <Button style={{ float: "right" }} size="small" color="primary" href={`https://www.localproject.app/UserProjects/${jobInfo.user}`}>
                Creator
              </Button>
              <Button size="small" color="primary" href={jobInfo.link}>
                Apply
              </Button>
            </CardActions>
          </Card>
        </Grid>
      ))}
      {/* Display the form if isFormOpen is true */}
    </>
  );
};
export default Jobs;