import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import { Grid } from '@material-ui/core';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker'

const Dates = ({updateParentDateState}) => {

const [date, setDate] = useState([null, null]);

const handleButtonClick = (dateRange) => {
    // Step 3: Invoke the callback function to send data back to the parent
    updateParentDateState(dateRange);
  };

  const handleDateChange = (newValue) => {
    setDate(newValue);

    if (newValue[0] && newValue[1]) {
        
      const startDate = newValue[0].toISOString().split('T')[0];
      const endDate = newValue[1].toISOString().split('T')[0];
      const dateRange = `${startDate} - ${endDate}`;
      handleButtonClick(dateRange); // Assuming you have a function to set the dates
    }
  };

  return (
    <Grid style={{position:"obsolute"}}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateRangePicker
          style={{ color: 'black' }}
          value={date}
          onChange={handleDateChange}
          renderInput={(startProps, endProps) => (
            <React.Fragment>
              <TextField
                style={{ color: 'black', marginTop: '0.5rem',maxWidth:"148px" }}
                {...startProps}
              />
              <TextField
                style={{ color: 'black',maxWidth:"148px" }}
                sx={{ ml: 0.5 }}
                {...endProps}
              />
            </React.Fragment>
          )}
        />
      </LocalizationProvider>
    </Grid>
  );
}
export default Dates;