import React, { useEffect, useState } from 'react'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardMedia from '@mui/material/CardMedia'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import HomePageBar from '../components/HomePageBar'
import Grid from '@mui/material/Grid'
import AddBlogForm from './AddBlogForm'
import LoginBarTop from '../components/LoginBarTop'
import BlogList from './BlogList'
import UploadImg from '../components/uploadImg'
import { getOnlineUsers } from '../services/OnlineService'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
const Blogs = () => {
  const { blogTitle } = useParams();
  const location = useLocation()
  const state = location.state || "";
  const realUser = state.realUser || "";
  const nav = useNavigate();
  const [isFormOpen, setIsFormOpen] = useState(false) // State to manage form visibility
  const [isUpload, setIsUpload] = useState(false);
  const [buttonImg, setButtonImg] = useState(false)
  const [blogsData, setBlogsData] = useState([])
  const [imgLinkZero, setImgLinkZero] = useState()
  const [imgLinkOne, setImgLinkOne] = useState()
  const [imgLinkTwo, setImgLinkTwo] = useState()
  const [imgLinkThree, setImgLinkThree] = useState()
  const handelUrlStringZero = () => {
    const newImg = document.getElementById('mainImg')?.currentSrc
    if (newImg) {
      setImgLinkZero(newImg);
    }
    setButtonImg(true)
  }
  const handelUrlStringOne = () => {
    const newImg = document.getElementById('mainImg')?.currentSrc
    if (newImg) {
      setImgLinkOne(newImg);
    }
    setButtonImg(true)
  }
  const handelUrlStringTwo = () => {
    const newImg = document.getElementById('mainImg')?.currentSrc
    if (newImg) {
      setImgLinkTwo(newImg);
    }
    setButtonImg(true)
  }
  const handelUrlStringThree = () => {
    const newImg = document.getElementById('mainImg')?.currentSrc
    if (newImg) {
      setImgLinkThree(newImg);
    }
    setButtonImg(true)
  }
  useEffect(() => {
    getBlogs()
  }, [])
  // Function to toggle the form visibility
  const toggleForm = () => {
    if (realUser === "") {
      nav("/Login");
    } else {
      setIsFormOpen(!isFormOpen);
      setIsUpload(!isUpload);
    }
  };
  let ImgId = document.getElementById("mainImg") || "";
  const getBlogs = async () => {
    if (blogTitle) {
      try {
          const blogDataResponse = await getOnlineUsers();
          const newBlogsData = blogDataResponse[0].blogs;
          const rows = Object.keys(newBlogsData).map(key => newBlogsData[key]);
          const filteredUser = rows.find(user => user.blogTitle === blogTitle);   
          if (filteredUser) {
            setBlogsData([filteredUser]);
          } else {
            console.log('User not found');
          }
        } catch (error) {
          console.error('Error fetching blogs:', error);
          throw new Error('Error fetching blogs');
        } 
    } else {
      try {
        const blogDataResponse = await getOnlineUsers();
        const newBlogsData = blogDataResponse[0].blogs;
        const rows = Object.keys(newBlogsData).map(key => {
          const { user, blogTitle, location, introduction, main, end, tags, imgLink0, imgLink1, imgLink2, imgLink3, contact, link } = newBlogsData[key];
          return {
            user,
            blogTitle,
            contact,
            location,
            introduction,
            main,
            end,
            tags,
            imgLink0,
            imgLink1,
            imgLink2,
            imgLink3,
            link
          };
        });
        setBlogsData(rows);
      } catch (error) {
        console.error('Error fetching jobs:', error);
        throw new Error('Error fetching jobs');
      }
    }
  }
  return (
    <>
      {realUser !== undefined && realUser !== "" ? (
        <>
          <LoginBarTop realUser={realUser} />
        </>
      ) : <HomePageBar />}
     
      {(isFormOpen && realUser !== "") && <AddBlogForm onClose={toggleForm} buttonImg={buttonImg} realUser={realUser} ImgId={ImgId} imgLinkZero={imgLinkZero} imgLinkOne={imgLinkOne} imgLinkTwo={imgLinkTwo} imgLinkThree={imgLinkThree} />}
      {(realUser !== undefined || realUser !== "Guest") && <Button style={{ marginLeft: '10px', marginBottom: '20px' }}  onClick={toggleForm} variant="outlined" color="primary">
              Add a New Blog
            </Button>}
      {(realUser !== "") &&  
   <Grid 
   container
   spacing={2} // Adds consistent spacing between all items
   sx={{ marginLeft: "-5px", alignItems: "center" }} // Align items vertically in the center
 >
   {(isUpload && realUser !== "") && <><Grid item>
            <Button variant="outlined" onClick={handelUrlStringZero}>
              Avatar
            </Button>
          </Grid><Grid item>
              <Button variant="outlined" onClick={handelUrlStringOne}>
                Top Image
              </Button>
            </Grid><Grid item>
              <Button variant="outlined" onClick={handelUrlStringTwo}>
                Middle Image
              </Button>
            </Grid><Grid item>
              <Button variant="outlined" onClick={handelUrlStringThree}>
                Bottom Image
              </Button>
            </Grid><Grid item>
              <UploadImg realUser={realUser} />
            </Grid></>}
 </Grid>
    
    }  
      
      {blogsData.map((blogInfo, index) => (
        <>
            <Grid item xs={12} sm={6} md={4} key={index}>
            <Card>
              <CardMedia
                component="Img"
                alt=""
                style={{ objectFit: "cover", height: '200px' }}
                image={blogInfo.imgLink1} //  imgLink is the image URL
              />
              <Avatar
                alt="Remy Sharp"
                src={blogInfo.imgLink0}
                sx={{ width: 140, height: 140, top: "-4.5rem", marginLeft: "2rem", border: "0.5rem solid white" }} />
              <CardContent item mt={5} ml={2}>
                <Typography mt={-18.5} sx={{ float: "right" }} variant="body1" color="textSecondary">
                  {blogInfo.location}
                </Typography>
                <Typography mt={-11.5} sx={{ marginLeft: "3rem" }} variant="body1" color="textSecondary">
                  {blogInfo.user}
                </Typography>
                <Typography mt={2} gutterBottom variant="h4" component="div">
                  {blogInfo.blogTitle}
                </Typography>
                <Typography mt={1} variant="h6" color="textSecondary">
                  Introducton
                </Typography>
                <Typography mt={1} sx={{ marginBottom: "2.8rem" }} variant="h7" color="textSecondary" className="break-words" >
                        {blogInfo.introduction.split('\n').map((line, index) => (
                          <React.Fragment key={index}>
                            {line}
                            <br />
                          </React.Fragment>
                        ))}
                      </Typography>
                <CardMedia
                  component="Img2"
                  alt="Blog Picture here"
                  style={{ objectFit: "cover", height: '28rem', marginTop: "2rem", marginBottom: "1.8rem" }}
                  image={blogInfo.imgLink2} // Assuming imgLink is the image URL
                />
                  <Typography mt={8} sx={{ marginBottom: "2.8rem" }} variant="h7" color="textSecondary" className="break-words" >
                        {blogInfo.main.split('\n').map((line, index) => (
                          <React.Fragment key={index}>
                            {line}
                            <br />
                          </React.Fragment>
                        ))}
                      </Typography>
                <CardMedia
                  component="Img3"
                  alt="Blog Picture here"
                  style={{ objectFit: "cover", height: '28rem', marginBottom: "1.8rem", marginTop: "1.8rem" }}
                  image={blogInfo.imgLink3} // Assuming imgLink is the image URL
                />
                <Typography mt={1} sx={{ marginBottom: "1.8rem" }} variant="h7" color="textSecondary" className="break-words" >
                        {blogInfo.end.split('\n').map((line, index) => (
                          <React.Fragment key={index}>
                            {line}
                            <br />
                          </React.Fragment>
                        ))}
                      </Typography>
                <Typography mt={2} variant="body2" color="textSecondary">
                  {blogInfo.contact}
                </Typography>
              </CardContent>
              <Button style={{ float: "left", marginTop: "0.5rem", marginRight: "0.5rem" }}
                variant="text"
                size="small"
                color="primary"
                href="https://www.localproject.app/Blogs"
                rel={`noopener noreferrer tag ${blogInfo.tags}`}
              >
                Tags {blogInfo.tags}
              </Button>
              <CardActions style={{ float: "right" }}>
                <Button size="small" color="primary" href={`https://www.localproject.app/UserProjects/${blogInfo.user}`}>
                  Creator
                </Button>
                <Button size="small" color="primary" href={blogInfo.link}>
                  Social Link
                </Button>
              </CardActions>
            </Card>
          </Grid>
         
     
            </>
      ))}
          <BlogList blogsData={blogsData}></BlogList>
    </>
  );
};
export default Blogs;