import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';
import CheckIcon from '@mui/icons-material/Check';
import SaveIcon from '@mui/icons-material/Save';
import CachedRoundedIcon from '@mui/icons-material/CachedRounded';
import ImageIcon from '@mui/icons-material/Image';
import Skeleton from '@mui/material/Skeleton';
import DownloadingIcon from '@mui/icons-material/Downloading';
import LoginBarTop from './LoginBarTop';
import ReplyIcon from '@mui/icons-material/Reply';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Footer from './Footer';
import CreateTask from './CreateTask';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Avatar from '@mui/material/Avatar';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ChatComponent from './FrontEndChat/ChatComponent';
import RecordVideo from './RecordVideo';
import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';
import { getInbox } from '../services/InboxService';
import { editTask, getUserTasks } from '../services/TaskService';
import { Typography } from '@material-ui/core';
import { deleteTask } from '../services/TaskService';
import { useLocation, useNavigate } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const UserTasks = ({ removeData }) => {
  const nav = useNavigate();
  const location = useLocation();
  if (location.pathname === "/UserTasks") {
    removeData = 0;
  }

  const state = location.state;
  const user = state.realUser;
  const realUser = state.realUser;
  const [rows, setRows] = useState([])
  const [success, setSuccess] = useState(false)
  const [updatedTasks, setUpdatedTasks] = useState(false)
  const [loading, setLoading] = useState(false)
  const [gotUserTasks, setGotUserTasks] = useState(false)
  const [isMessageFormOpen, setMessageFormOpen] = useState(false);
  const [imgUser, setImgUser] = useState();

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const tasks = await getUserTasks(user);
        const updatedRows = Object.keys(tasks).map((key) => {
          const { _id, task, status, createdBy: name, assignee, createdAt, message, imgLink } = tasks[key] || {};
          return { _id, task, status, name, assignee, createdAt, message, imgLink };
        });
        setRows([...updatedRows]);
        setGotUserTasks(true);
      } catch (error) {
        console.error('Error fetching tasks:', error);
      }
      return () => { };
    };

    async function fetchUserImg() {
      try {
        const loggedUser = { user: user }
        const response = await getInbox(loggedUser);
        const result = await response;
        const imgLink = result[0].userImg[0].imgLink
        setImgUser(imgLink);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
    fetchTasks() // Call the fetchTasks function
    fetchUserImg()
  }, [user, success, updatedTasks, gotUserTasks]);

  if (realUser === null) { nav('/'); }
  // Event handler to update the input value
  const handleOpenMessageForm = () => {
    setMessageFormOpen(true);
  };

  const MyDialogBox = React.memo((task) => {
    const [message, setMessage] = useState('');
    const handleCloseMessageForm = () => {
      setMessageFormOpen(false);
    };

    const handleSendMessageForm = (value, message) => {
      if (value.task === null) {
        return value.task = "";
      }
      const data = { _id: value.task._id, assignee: value.task.name, message, status: value.task.status, task: value.task.task }
      editTask(data).then(response => {
        setSuccess(true);
      });
      setMessageFormOpen(false);
    };
    const handleInputChange = (event) => {
      setMessage(event);
    };
    return (
      <Dialog open={isMessageFormOpen} onClose={handleCloseMessageForm}>
        <DialogTitle>Send Task Back</DialogTitle>
        <DialogContent>
          <TextField
            id="reply"
            onChange={(e) => handleInputChange(e.target.value)}
          ></TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCloseMessageForm()} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleSendMessageForm(task, message)} color="primary">
            Send
          </Button>
        </DialogActions>
      </Dialog>
    );
  });

  const moveTaskInProgress = async (event) => {
    if (!loading) {
      const { _id, task } = event;
      try {
        const data = {
          _id,
          status: 'In Progress',
          message: `Done by ${realUser}`,
          assignee: realUser,
          task
        };
        setRows((prevRows) => {
          return prevRows.map((row) => {
            if (row._id === _id && row.status === 'To Be Done') {
              return { ...row, status: 'In Progress' };
            }
            return row;
          });
        });
        // Call editTask and await its completion
        await editTask(data);
      } catch (err) {
        console.error(err);
      }
    }
  }

  const handleButtonClick = async (event) => {
    if (!loading) {
      const { _id, task } = event;
      try {
        const data = {
          _id,
          status: 'Completed',
          message: `Done by ${realUser}`,
          assignee: realUser,
          task
        };
        setRows((prevRows) => {
          return prevRows.map((row) => {
            if (row._id === _id && row.status === 'In Progress') {
              return { ...row, status: 'Completed' };
            }
            return row;
          });
        });
        // Call editTask and await its completion
        await editTask(data);
      } catch (err) {
        console.error(err);
      }
    }
  };

  const delTask = async (event) => {
    const { _id } = event;
    try {
      await deleteTask(_id);
      setSuccess(prevTaskEdited => !prevTaskEdited);
    } catch (error) {
      console.error('Error deleting task:', error);
    }
  };

  const tasksToDo = rows.filter((task) => task.status === 'To Be Done');
  const tasksDeleted = rows.filter((task) => task.status === 'Deleted');
  const tasksInProgress = rows.filter((task) => task.status === 'In Progress');
  const tasksCompleted = rows.filter((task) => task.status === 'Completed');

  const onDragEnd = async (result) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }

    const tasks = [...rows];
    const [removed] = tasks.splice(source.index, 1);
    tasks.splice(destination.index, 0, removed);

    const _id = result.draggableId;
    const newStatus = destination.droppableId;

    if (newStatus === "Delete") {
      console.log("Deleting task with _id:", _id);
      await deleteTask(_id);
      setSuccess(prevTaskEdited => !prevTaskEdited);
    } else {
      console.log("Updating task with _id:", _id, "to status:", newStatus);
      const data = { _id, status: newStatus, assignee: realUser, message: "" };
      await editTask(data);

      setRows((prevRows) => {
        return prevRows.map((row) => {
          if (row._id === _id && row.status === newStatus) {
            return { ...row, status: newStatus };
          }
          return row;
        });
      });
      setSuccess(prevTaskEdited => !prevTaskEdited);
    }
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
  }));

  function formatMongoDate(mongoDate) {
    const jsDate = new Date(mongoDate);
    // Define options to format the date without seconds
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    };
    const formattedDate = jsDate.toLocaleString(undefined, options);
    return formattedDate;
  }

  return (
    <>
      {removeData === 0 && <LoginBarTop setUser={user} user={user} realUser={realUser} />}
      <CreateTask setUser={user} user={user} imgUser={imgUser} updatedTasks={updatedTasks} setUpdatedTasks={setUpdatedTasks} />

      <Grid container={removeData === 0} spacing={removeData === 1 ? 1 : 1} columns={8} mt={1}>
        <Card item sx={{ minWidth:"100%", marginLeft: "10px", marginRight: "10px", marginBottom: "10px" }} >
          <Item>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId={"To Be Done"}>
                {(provided, snapshot) => (
                  <div
                    className={`tasks-column${snapshot.isDraggingOver ? ' dragging-over' : ''}`}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    <Typography style={{ fontSize: '24px', backgroundColor: "lavenderblush", borderBottom: '1px solid #333', padding: "10px" }}>
                    </Typography>
                    {tasksToDo.length === 0 && <p>No tasks To Be Done</p>}
                    {tasksToDo.map((task, index) => (
                      <Draggable key={task._id} draggableId={task._id} index={index}>
                        {(provided) => (
                          <Box
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                          >
                            <ListItem value={task._id} className="buttonStyle">
                              <ListItemAvatar>
                                <Avatar src={task.imgLink}>
                                  <ImageIcon />
                                </Avatar>
                              </ListItemAvatar>
                              <ListItemText value={task._id} primary={`${task.name} ${formatMongoDate(task.createdAt)}`} secondary={`${task.task} - ${task.message}`} />
                              <MyDialogBox task={task}></MyDialogBox>
                              <Tooltip title="Reply to task creator" arrow>
                                <Fab
                                  sx={{
                                    float: 'right', marginLeft: "0.8rem"
                                  }}
                                  value={task._id}
                                  onClick={() => handleOpenMessageForm()}
                                >
                                  {task.status === 'To Be Done'} <ReplyIcon />
                                </Fab>
                              </Tooltip>
                              <Tooltip title="Move to in progress" arrow>
                                <Fab
                                  sx={{
                                    float: 'right',
                                  }}
                                  value={task._id}
                                  onClick={() => moveTaskInProgress(task)}
                                >
                                  {task.status === 'To Be Done'} <DownloadingIcon />
                                </Fab>
                              </Tooltip>
                            </ListItem>
                          </Box>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
              <div className="tasks-container">
                <Droppable droppableId="In Progress">
                  {(provided, snapshot) => (
                    <div
                      className={`tasks-column${snapshot.isDraggingOver ? ' dragging-over' : ''}`}
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      <h2 style={{ fontSize: '24px', backgroundColor: "lightblue", borderBottom: '1px solid #333', padding: '10px', marginTop: '10px' }}>
                      </h2>
                      {tasksInProgress.length === 0 && <p>No Tasks In Progress</p>}
                      {tasksInProgress.map((task, index) => (
                        <Draggable key={task._id} draggableId={task._id} index={index}>
                          {(provided) => (
                            <Box
                              value={task._id}
                              className="card-image"
                              style={{ marginBlock: '20px', marginTop: '20px' }}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                            >
                              <ListItem value={task._id} className="buttonStyle">
                                <ListItemAvatar>
                                  <Avatar src={task.imgLink}>
                                    <ImageIcon />
                                  </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={`${task.name} ${formatMongoDate(task.createdAt)}`} secondary={task.task} />
                                <Tooltip title="Complete" arrow>
                                  <Fab
                                    style={{ marginLeft: "0.8rem" }}
                                    variant="contained"
                                    data-task-id={task._id}  // Store task._id as a data attribute
                                    onClick={() => handleButtonClick(task)}
                                  >
                                    {task.status === 'In Progress'}
                                    <CachedRoundedIcon />
                                  </Fab>
                                </Tooltip>
                              </ListItem>
                            </Box>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
              <div className="tasks-container">
                <Droppable droppableId="Completed">
                  {(provided, snapshot) => (
                    <div
                      className={`tasks-column${snapshot.isDraggingOver ? ' dragging-over' : ''}`}
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      <h2 style={{ fontSize: '24px', backgroundColor: "lightskyblue", borderBottom: '1px solid #333', padding: '10px', marginTop: "10px" }}>
                      </h2>
                      {tasksCompleted.length === 0 && <p>No Tasks Completed</p>}
                      {tasksCompleted.map((task, index) => (
                        <Draggable key={task._id} draggableId={task._id} index={index}>
                          {(provided) => (
                            <Box
                              className="card-image"
                              style={{ marginBlock: '20px', marginTop: '20px' }}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                            >
                              <ListItem className="buttonStyle">
                                <ListItemAvatar>
                                  <Avatar src={task.imgLink}>
                                    <ImageIcon />
                                  </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={`${task.name} ${formatMongoDate(task.createdAt)}`} secondary={task.task} />
                                <Tooltip title="Delete" arrow>
                                  <Fab
                                    style={{ marginLeft: "0.8rem" }}
                                    variant="contained"
                                    value={task._id}
                                    data-task-id={task._id}
                                    onClick={() => delTask(task)}
                                  >
                                    {task.status === 'Completed'}
                                    <CheckCircleOutlineIcon></CheckCircleOutlineIcon>
                                  </Fab>
                                </Tooltip>
                              </ListItem>
                            </Box>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
              <div className="tasks-container">
                <Droppable droppableId="Delete">
                  {(provided, snapshot) => (
                    <div
                      className={`tasks-column${snapshot.isDraggingOver ? ' dragging-over' : ''}`}
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      <h2 style={{ fontSize: '24px', backgroundColor: "lightgoldenrodyellow", borderBottom: '1px solid #333', padding: '10px', marginTop: "10px" }}>
                      </h2>
                      {tasksDeleted.length === 0 && <p>Nothing deleted</p>}
                      {tasksDeleted.map((task, index) => (
                        <Draggable key={task._id} draggableId={task._id} index={index}>
                          {(provided) => (
                            <Box
                              className="card-image"
                              style={{ marginBlock: '20px', marginTop: '20px' }}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                            >
                              {loading ? (
                                <Box
                                  style={{
                                    marginBlock: '10px', background: 'none'
                                  }}>
                                  <Skeleton
                                    variant="rectangular"
                                    height={40}
                                    placeholder={task.task}
                                    animation
                                    wave />
                                </Box>
                              ) : (
                                <div
                                  className="box buttonStyle"
                                  style={{ color: 'white' }}
                                  variant="body2"
                                  color="text.primary"
                                  component="p"
                                >
                                  {task.assignee} - {task.task}
                                  <Box
                                    sx={{
                                      display: 'inline-flex',
                                      float: 'right',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <Fab
                                      variant="contained"
                                      value={task._id}
                                    >
                                      {task.status === 'Delete' ? (
                                        <CheckIcon />
                                      ) : (
                                        <SaveIcon />
                                      )}
                                    </Fab>
                                  </Box>
                                </div>
                              )}
                            </Box>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            </DragDropContext></Item>
        </Card>
        {removeData === 0 && (
          <>
              <Grid item sx={{ minWidth: "100%" }}>
                <ChatComponent></ChatComponent>
              </Grid>
              <Grid item sx={{ maxWidth: "100%" }}>
                <Item>
                  <h2 style={{ fontSize: '24px', borderBottom: '1px solid #333', paddingBottom: '10px' }}>
                    <RecordVideo></RecordVideo>
                    <div>
                    </div>
                  </h2>
                </Item>
            </Grid>
          </>
        )}
      </Grid>
      {removeData === 0 && <Footer />}</>
  );
};
export default UserTasks;