import * as React from 'react'
import { useState,useEffect } from "react"
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Avatar from '@mui/material/Avatar'
import IconButton from "@mui/material/IconButton"
import CloseIcon from '@mui/icons-material/Close'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { useNavigate} from 'react-router-dom'
import { shareProject } from '../services/SharedService';

export default function RecentUsers({  realUser }) {
  const [isUserProfileOpen, setIsUserProfileOpen] = useState(false);
  const [online, setOnline] = useState([]);

  function uniq(a) {
    return Array.from(new Set(a));
  }

  useEffect(() => {
    shareProject()
      .then((response) => {
        const newMarkers = response[0].projects;
        const countOnliners = [];
        for (const key in newMarkers) {
          const element = newMarkers[key]?.location;
          const _id = newMarkers[key]?._id;
          const splitLocation = element.split(',');
          const projectName = splitLocation[2]?.trim();
          const createdBy = splitLocation[3]?.trim();
          const imgUser = splitLocation[4]?.trim();
          const data = { _id, createdBy, projectName, imgUser };
          countOnliners.push(data);
        }
        const shuffled = countOnliners.sort(() => 0.5 - Math.random());
        const shuffleds = uniq(shuffled);
        const unique = [...new Map(shuffleds.map((m) => [m._id, m])).values()];
        setOnline(unique.slice(0, 4));
      })
      .catch((error) => {
        console.error(error);
      });
      return () => {}
  }, []);

  const handleOpenUserProfile = () => {
    setIsUserProfileOpen(true);
  };
  const handleCloseUserProfile = () => {
    setOnline([])
    setIsUserProfileOpen(false);
  };
  const nav = useNavigate()
  const isUserProfile = (e) => {
    const userId = e.currentTarget.id;
    nav(`/UserProjects/${userId}`, { state: { user: e.currentTarget.id, realUser } });
  }
  return (
    <>
      { online.map((item, index) => (
        <Card
          key={item._id}   
          item
          sx={{ float: index % 1 === 0 ? 'right' : 'right', height:"60px",marginRight:"10px",marginTop:"35px" }}
        >
          <CardHeader
            avatar={
              <Avatar   
                src={item.imgUser}
                aria-label={item.createdBy}
                style={{ marginTop:"-0.3rem" }}
              >
                {item[0]}
              </Avatar>
            }
            action={
              isUserProfileOpen ? (
                <><IconButton
                  aria-label="close"
                  onClick={handleCloseUserProfile}
                >
                  <CloseIcon />
                </IconButton><Button
                  variant="text"
                  id={item.createdBy}
                  style={{ float: "right",marginTop:"0.1rem" }}
                  onClick={isUserProfile}
                >
                    {item.projectName}
                  </Button></>
              ) : (
                     <IconButton
                     aria-label="settings"
                     onClick={handleOpenUserProfile}
                   >
                    <MoreVertIcon />
                  </IconButton>
              )
            }
            subheader={item.createdBy}
            on={{ isUserProfile }}
          /> 
        </Card>
      ))}
    </>
  )
};