import { useState, useEffect,useRef } from 'react';
import { shareProject } from '../services/SharedService';
import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import Avatar from '@mui/material/Avatar';
import { styled, alpha } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import InputBase from '@mui/material/InputBase';
import ListItemText from '@mui/material/ListItemText';

const SearchSkills = ({ realUser }) => {
  const nav = useNavigate();
  const [query, setQuery] = useState('');
  const [data, setData] = useState([]);
  const [debouncedQuery, setDebouncedQuery] = useState('');
  const [timeoutId, setTimeoutId] = useState(null);
  const [isFocused, setIsFocused] = useState(false);
  const [aLoading, isALoading] = useState(false);
  const [isInputOpen, setInputOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const inboxes = await shareProject();
      let checkUsersSkills = inboxes[0].projects;
      const newData = Object.keys(checkUsersSkills).map(key => {
        const location = checkUsersSkills[key].location;
        const [latitude, longitude, activity, username, img] = location.split(',');
        return { latitude, longitude, activity, username, img };
      });
      const uniqueUsernames = [...new Set(newData.map(item => item.username))];
      const filteredData = uniqueUsernames.map(username => newData.find(item => item.username === username));
      setData(filteredData);
    };
    if (debouncedQuery !== null) {
      fetchData();
    }
  }, [debouncedQuery]);

  const inputRef = useRef(null);


  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: isInputOpen ? '100%' : '0', // Slide in effect
      [theme.breakpoints.up('sm')]: {
        width: isInputOpen ? '20ch' : '0', // Slide in effect
      },
    },
  }));

  const Search = styled('div')(({ theme }) => ({
    position: 'relative',
   
    float:"right",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    zIndex: '999',
    marginLeft: 0,
    border: '1px solid white',
    width: isInputOpen ? '100%' : '60px', // Slide in effect
    height: '37px',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: isInputOpen ? 'auto' : '60px', // Slide in effect
    },
  }));

  const SearchIconWrapper = styled('div')(({ theme, aLoading }) => ({
    padding: theme.spacing(0.8, 2),
    height: '80%',
    position: 'absolute',
    zIndex: '999',
    animation: `${aLoading ? 'spin 1s linear infinite' : 'none'}`,
  }));



  const handleSearchIconClick = () => {
    setInputOpen(!isInputOpen);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleChange = (e) => {
    const inputValue = e.target.value;
    setQuery(inputValue);
    isALoading(true);
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    const newTimeoutId = setTimeout(() => {
      setDebouncedQuery(inputValue);
      isALoading(false);
    }, 300);
    setTimeoutId(newTimeoutId);
  };

  const userProjectShow = (user) => {
    nav(`/UserProjects`, { state: { user, realUser } });
  };

  return (
    <Search
      style={{
        marginRight: '1rem',
        height:"2.5rem",
        color: ' #444444',
        backgroundColor: 'white',
        marginTop:"0.0.9rem",
        fontStyle: 'normal',
        borderRadius: '5px',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
      }}
    >
      <SearchIconWrapper  style={{
           
            zIndex: '999999999999',
           
           
          }} aLoading={aLoading} onClick={handleSearchIconClick}>
        <SearchIcon />
      </SearchIconWrapper>
      <StyledInputBase 
        ref={inputRef}
        value={query}
        id="locationInput"
        placeholder="Search..."
        inputProps={{ 'aria-label': 'Search' }}
        onChange={handleChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setInputOpen(false)}
        autoFocus={isFocused}
      />
      {data.filter((post) => {
        if (debouncedQuery.length < 1) {
          return null;
        } else if (post.activity.toLowerCase().includes(query.toLowerCase())) {
          return post;
        }
      }).map((post, index) => (
        <ListItem
          
          onClick={() => userProjectShow(post.username)}
          key={index}
          id={post.username}
          style={{
            marginTop: '1px',
            zIndex: '999999999999',
            backgroundColor: 'whitesmoke',
            cursor: 'pointer',
          }}
        >
          <Avatar id={post.username} alt="Volunteers" src={post.img} />
          <ListItemText
            key={index +1}
            style={{ marginLeft: '5px', color: 'black' }}
            primary={post.username + ' & ' + post.activity}
          />
        </ListItem>
      ))}
    </Search>
  );
};

export default SearchSkills;
